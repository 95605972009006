import * as React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ICheckIn } from "../../../../../../types/checkIn.types";
import { Box } from "../../../../Box";
import { Pane } from "../../../customerservicemodal/panes/Pane";
import { OrderInfoTabs } from "./OrderInfoTabs";
import { SalesOrderInfo } from "./SalesOrderInfo";
import { ManualOrderInfo } from "./ManualOrderInfo";
import { Tabs } from "antd";
import { filterOutBlendComponentsInProductionOrder } from "../../../../../../utils/order";

interface IProps {
    checkIn: ICheckIn;
}

const MESSAGES = defineMessages({
    title: {
        id: "check_in_modal.order_info_pane.title",
        defaultMessage: "Order info"
    },
    orderType: {
        id: "checkin.order_info.order_type",
        defaultMessage: "Order type"
    },
    state: {
        id: "checkin.order_info.state",
        defaultMessage: "Status"
    },
    reference: {
        id: "checkin.order_info.reference",
        defaultMessage: "Unieke referentie"
    },
    assignmentNumber: {
        id: "checkin.order_info.assignment_number",
        defaultMessage: "Opdracht nummer"
    },
    customer: {
        id: "checkin.order_info.customer",
        defaultMessage: "Customer"
    },
    unNumber: {
        id: "checkin.order_info.un_number",
        defaultMessage: "UN nummer"
    },
    product: {
        id: "checkin.order_info.product",
        defaultMessage: "Product"
    },
    customerReference: {
        id: "checkin.order_info.customer_reference",
        defaultMessage: "Klant referentie"
    },
    tank: {
        id: "checkin.order_info.tank",
        defaultMessage: "Tank"
    },
    timeslot: {
        id: "checkin.order_info.time_slot",
        defaultMessage: "Timeslot"
    },
    amount: {
        id: "checkin.order_info.amount",
        defaultMessage: "Hoeveelheid"
    },
    destination: {
        id: "checkin.order_info.destination",
        defaultMessage: "Bestemming"
    },
    overDue: {
        id: "checkin.order_info.overdue",
        defaultMessage: "Chauffeur te laat"
    },
    mailSent: {
        id: "checkin.order_info.mail_sent",
        defaultMessage: "Chauffeur te laat, mail verstuurd"
    },
    linkSalesOrder: {
        id: "checkin.order_info.linkSalesOrder",
        defaultMessage: "Koppel salesorder"
    },
    editSalesOrder: {
        id: "checkin.order_info.editSalesOrder",
        defaultMessage: "Wijzig salesorder"
    },
    destinationEu: {
        id: "checkin.order_info.destinationEu",
        defaultMessage: "Binnen de eu"
    },
    multipleProducts: {
        id: "checkin.order_info.multiple_products",
        defaultMessage: "Meerdere producten"
    },
    temperatureProduct: {
        id: "checkin.temperature_product",
        defaultMessage: "Temperatuur product"
    },
    degreesCelsius: {
        id: "temperature.degrees_celsius",
        defaultMessage: "°C"
    },
    cmrWeight: {
        id: "checkin.order_info.cmrWeight",
        defaultMessage: "CMR gewicht"
    }
});

export const OrderInfoPane = (props: IProps) => {
    const intl = useIntl();

    const productionOrders = props.checkIn.salesOrders?.flatMap(s => s.productionOrders) || [];
    const manualOrders = props.checkIn.manualOrders || [];

    return (
        <Pane title={intl.formatMessage(MESSAGES.title)}>
            <Box>
                {!!manualOrders.length &&
                <>
                    {manualOrders.length === 1 ?
                        <ManualOrderInfo
                            MESSAGES={MESSAGES}
                            manualOrder={manualOrders[0]}
                            checkIn={props.checkIn}
                        /> :
                        <Tabs destroyInactiveTabPane animated={false}>
                            {manualOrders.map((manualOrder) => (
                                <Tabs.TabPane key={manualOrder.id} tab={manualOrder.orderNumber}>
                                    <ManualOrderInfo
                                        MESSAGES={MESSAGES}
                                        manualOrder={manualOrder}
                                        checkIn={props.checkIn}
                                    />
                                </Tabs.TabPane>
                            ))
                            }
                        </Tabs>
                    }
                </>
                }
                {!manualOrders.length &&
                props.checkIn.salesOrders &&
                <>
                    { (props.checkIn.salesOrders.length === 1 || productionOrders.filter(filterOutBlendComponentsInProductionOrder).length === 1) &&
                    <SalesOrderInfo
                        MESSAGES={MESSAGES}
                        productionOrder={props.checkIn.salesOrders[0].productionOrders.filter(filterOutBlendComponentsInProductionOrder)[0]}
                        salesOrder={props.checkIn.salesOrders[0]}
                        checkIn={props.checkIn}
                    />
                    }
                    {productionOrders.length > 1 && props.checkIn.salesOrders?.length > 1 &&
                    <OrderInfoTabs MESSAGES={MESSAGES} checkIn={props.checkIn}/>
                    }
                </>
                }
            </Box>
        </Pane>
    );
};
