import { IManualOrder, ISalesOrderDTO, StorageLocation } from "./salesOrder.types";
import { IRemark } from "./remarks.types";

export enum AdrVehicleSpecification {
    FL = "FL",
    AT = "AT",
    FL_AT = "FL_AT"
}

export interface IAdrCertificate {
    adrCode: string;
    adrExpirationDate: string;
}

interface IVehicle {
    id: number;
    licensePlate: string;
    adrCertificate?: IAdrCertificate;
}

export interface IDriver {
    firstName: string;
    lastName: string;
    transportCompany: string;
    adrCertificate?: IAdrCertificate;
    dateOfBirth: string;
    countryCallingCode: string;
    phoneNumber: string;
}

export interface ISupplier {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    company: string;
    licensePlate: string;
    countryCallingCode: string;
    phoneNumber: string;
    terminal: StorageLocation | null;
}

export interface IContractor {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    company: string;
    driver: boolean;
    licensePlate?: string;
    countryCallingCode?: string;
    phoneNumber?: string;
    dateOfSuccessPerTerminal: IDateOfSuccessPerTerminal;
    terminal: StorageLocation | null;
}

export interface IDateOfSuccessPerTerminal {
    [key: string]: string;
}

export enum CheckInReason {
    BULK = "BULK",
    WRAPPED = "WRAPPED",
    CONTAINER = "CONTAINER",
    VISIT = "VISIT",
    SUPPLIER = "SUPPLIER",
    CONTRACTOR = "CONTRACTOR",
}

export interface ICompartment {
    order: number;
    capacity: number;
    nitrogen: boolean;
    cleaned: boolean;
    restProduct: boolean;
    loadAmount: number;
    productionOrderId: number | null;
    baffles?: boolean;
    loadAmountInLiter: number;
    shouldUnload?: boolean;
    toleranceLoadAmount?: number
    toleranceLoadAmountInLiter?: number
}

export interface IContainer {
    containerNumber: string;
    adrCertificate?: IAdrCertificate;
    tareWeight: number | null;
    tankCode: string | null;
}

export interface ITractor extends IVehicle {
    adrVehicleSpecification?: AdrVehicleSpecification;
}

export type IChassis = IVehicle;

export type ITanker = IVehicle;

export enum CheckInState {
    IN_PROGRESS = "IN_PROGRESS",
    QUEUED = "QUEUED",
    PROCESSING = "PROCESSING",
    READY_FOR_OPERATIONS = "READY_FOR_OPERATIONS",
    BACK_TO_KIOSK = "BACK_TO_KIOSK",
    FINISHED = "FINISHED",
    CANCELLED = "CANCELLED",
}
export const BLACKLISTED = "BLACKLISTED";

export const EXTENDED_CHECK_IN_STATE = [...Object.values(CheckInState) , BLACKLISTED] as const;

export type ExtendedCheckInState = typeof EXTENDED_CHECK_IN_STATE[0];


export enum CheckInEvent {
    ASSIGN = "ASSIGN",
    UN_ASSIGN = "UN_ASSIGN",
    FINISH = "FINISH",
    REOPEN = "REOPEN",
    RETURN_TO_KIOSK = "RETURN_TO_KIOSK",
    TRUCKER_STARTS_OVER = "TRUCKER_STARTS_OVER"
}

export enum TruckComposition {
    TRACTOR_TANKER = "TRACTOR_TANKER",
    TRACTOR_CHASSIS_CONTAINER = "TRACTOR_CHASSIS_CONTAINER",
    TRACTOR_CHASSIS_CONTAINER_FLEXIBAG = "TRACTOR_CHASSIS_CONTAINER_FLEXIBAG",
    TRACTOR_TRAILER = "TRACTOR_TRAILER",
    TRACTOR = "TRACTOR"
}

export enum TransportationMethod {
    ROAD = "ROAD",
    ROAD_RAIL = "ROAD_RAIL",
    ROAD_SEA = "ROAD_SEA"
}

export enum WeightCalculationDecision {
    // Everything's okay. The weight is within its normal range.
    NORMAL = "NORMAL",
    // The weight is more then allowed but within a 2% range.
    MAXIMIZED = "MAXIMIZED",
    // The weight cannot be fit within the 2% extra. this should be resolved manually with the dispatcher.
    OVERLOADED = "OVERLOADED"
}

export interface IVisitor {
    firstName: string;
    lastName: string;
    terminal: StorageLocation | null;
    countryCallingCode?: string;
    phoneNumber?: string;
}

export interface IWeightCalculation {
    weightBridge: number;
    totalOrderWeight: number;
    totalOrderWeightWithCorrection: number;
    maxAllowedWeight: number;
    maxAllowedWeightWithCorrection: number;
    percentageMaxAllowed: number;
    correction: number;
    totalWeightBeforeCorrection: number;
    totalWeight: number;
    decision: WeightCalculationDecision;
}

export interface ICheckIn {
    compartmentDistributions: ICompartment[];
    lenelBadge: string | null;
    wiegandBadge: string | null;
    cmrWeightDriver: number | null;
    cmrWeightDispatcher: number | null;
    cmrProductName: string | null;
    id: number;
    driver?: IDriver;
    supplier?: ISupplier;
    contractor? : IContractor;
    salesOrders?: ISalesOrderDTO[];
    certificates?: ICertificate[];
    manualOrders?: IManualOrder[];
    checkInState: CheckInState;
    extendedCheckInState: ExtendedCheckInState;
    assignedTo?: string;
    container?: IContainer;
    tractor?: ITractor;
    chassis?: IChassis;
    tanker?: ITanker;
    borderCrossingLocation?: string;
    transportationMethod?: TransportationMethod;
    visitor?: IVisitor;
    checkInReason: CheckInReason;
    cancelReason: string;
    truckComposition?: TruckComposition;
    flexibagCapacity?: number;
    weightCalculation: IWeightCalculation | null;
    exemptAdr: boolean;
    completedOn: string | null;
    initialWeight: number;
    weighingDate: string;
    overridden: boolean;
    maxWeightExceeded: boolean;
    isEditable: boolean;
    adrExpired: boolean;
    temperatureProduct?: number;
    remarks: IRemark[];
    supplierReference?: string;
    requiresWeightBadge: boolean;
    cmrWeightContainer: null | string;
}

export interface IUpdateCheckInParameters {
    cmrWeightDispatcher?: number;
    reservationIds?: string[];
}

export interface IBadgeParameters {
    badgeSerial: string;
}

export interface ICertificate {
    name: string;
    present: boolean;
    expirationDate: string;
    expired: boolean;
}
